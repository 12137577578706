<template>
  <div v-if="media" class="mb-40 bg-grey-200 lg:mx-20">
    <ContentGallery v-if="media.type === 'gallery'" :main="true" lazy-img="eager" :componentContent="{ gallery: mediaAlter }" />
    <template v-else>
      <div class="relative overflow-hidden bg-grey-300 after:block after:absolute after:inset-0" :class="mediaStyle">
        <div class="relative z-10 h-full">
          <AllerImgixImg v-if="media.type === 'image' && mediaSrc && mediaAlter" :media="mediaAlter" class="rounded-t-10 h-full w-full object-cover" :preload="true" sizes="(max-width: 1024px) 99vw, 940px" :modifiers="{ ar: '394:221', fit: media.autoCrop ? 'crop' : 'min' }" width="394" height="221" :fetchpriority="fetchPriority" :renderWidths="[300, 360, 380, 394, 410, 600, 790, 1020, 1080, 1280, 1440, 1900]" />
          <VideoWrapper v-else :media="media" :related="false" />
        </div>
      </div>
      <DescriptionAndCredits :show="!!descriptionAlter || !!creditsAlter" :description="descriptionAlter" :credits="creditsAlter" />
    </template>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  media: {
    type: Object as PropType<Aller.Media>,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  description: {
    type: String,
    default: '',
  },
  alt: {
    type: String,
    default: '',
  },
  credits: {
    type: String,
    default: '',
  },
  fetchPriority: {
    type: String,
    default: 'high',
    validator: (val: string) => ['auto', 'high', 'low'].includes(val),
  },
});

const { mediaSrc } = useAllerMedia(props.media || null);

const mediaAlter = props.media;
if (props.alt && !!mediaAlter) {
  mediaAlter.alt = props.alt;
  if (mediaAlter.media?.length) {
    mediaAlter.media[0].alt = props.alt;
  }
}

const descriptionAlter = props.description || props.media?.description || undefined;
const creditsAlter = props.credits || props.media?.credits || undefined;

/**
 * Style object for the media wrapper.
 */
const mediaStyle = {
  'after:z-0 after:bg-gradient-to-r after:from-transparent after:via-white after:to-transparent after:animate-shimmer': !!props.media,
  'aspect-video': props.media?.autoCrop,
};
</script>